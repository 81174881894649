@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply normal-case;
  }
  .btn-gradient {
    @apply border-none text-white bg-gradient-primary;
  }
  .btn.btn-disabled,
  .btn[disabled],
  .btn:disabled {
    color: hsl(var(--pc) / var(--tw-text-opacity));
  }
  .btn-neutral {
    @apply text-dark-base-content-04 border border-dark-base-content-04 hover:border hover:border-dark-base-content-04;
  }
  .btn-secondary {
    @apply text-white;
  }
  .checkbox {
    @apply h-[16px] w-[16px] border border-dark-base-content-06;
  }
  .select {
    @apply bg-dark-base-100 placeholder-dark-base-content-04 my-2;
  }
  .input {
    @apply bg-dark-base-100 placeholder-dark-base-content-04 my-2;
  }
  .textarea {
    @apply bg-dark-base-100 placeholder-dark-base-content-04 my-2;
  }
  .label {
    @apply pt-1 pb-1 pl-0;
  }
  .label-text-required::after {
    @apply ml-1 text-secondary content-['*'];
  }
  .form-control {
    @apply my-2;
  }
  .p-with-dot::before {
    @apply inline-block w-[3px] h-[3px] bg-dark-base-content mr-[0.2rem] align-middle content-[''];
  }
  .tab {
    @apply h-[3rem] px-0 mr-[1rem] leading-[24px];
  }
  .tab-active {
    @apply border-b-[3px] !border-secondary;
  }
  .btn-plain {
    @apply bg-transparent border border-dark-base-content-04 text-dark-base-content-04 hover:bg-dark-base-content-04 hover:text-dark-base;
  }
  .divider {
    @apply before:bg-dark-base-content/[0.1] after:bg-dark-base-content/[0.1];
  }
  .step {
    @apply after:!rounded-none after:!w-[15px] after:!h-[15px];
  }
  .steps-vertical .step:before {
    @apply w-[2px] h-[50%] translate-y-[-100%];
  }
  .text-2xl-semibold {
    /* Use the Space Grotesk font family */
    font-family: "Space Grotesk", sans-serif;
    /* Set font size to 24px */
    font-size: 24px;
    /* Set font weight to 600 (semi-bold) */
    font-weight: 600;
    /* Set line height to 32px */
    line-height: 32px;
    /* Set text alignment to left */
    text-align: left;
  }
  .text-2xl-medium {
    font-family: Space Grotesk;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  .text-xl-medium {
    /* Use the Space Grotesk font family */
    font-family: "Space Grotesk", sans-serif;
    /* Set font size to 20px */
    font-size: 20px;
    /* Set font weight to 500 (medium) */
    font-weight: 500;
    /* Set line height to 28px */
    line-height: 28px;
    /* Set text alignment to left */
    text-align: left;
  }
  .text-sm {
    /* Use the Space Grotesk font family */
    font-family: "Space Grotesk", sans-serif;
    /* Set font size to 14px */
    font-size: 14px;
    /* Set font weight to 400 (normal) */
    font-weight: 400;
    /* Set line height to 20px */
    line-height: 20px;
    /* Set text alignment to left */
    text-align: left;
  }
  .text-base {
    font-family: "Space Grotesk";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .text-sm-medium {
    font-family: "Space Grotesk";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  .text-xs {
    /* Use the Space Grotesk font family */
    font-family: "Space Grotesk", sans-serif;
    /* Set font size to 12px */
    font-size: 12px;
    /* Set font weight to 400 (normal) */
    font-weight: 400;
    /* Set line height to 16px */
    line-height: 16px;
    /* Set text alignment to left */
    text-align: left;
  }
}

.table-divider {
  border-color: #8b8b8b1a; /* 设置表格分隔线颜色 */
}

@font-face {
  font-family: "Space Grotesk";
  src: url("/src/assets/fonts/SpaceGrotesk-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Iceland";
  src: url("/src/assets/fonts/Iceland-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

svg {
  display: inline;
}
